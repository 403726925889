import React from 'react';
import teams from '../../images/integrations/ms-teams.svg';
import IntegrationBot from '../../components/integrations/indegrations';

const MSTeams = () => (
  <IntegrationBot
    botLogo={teams}
    botName={'Microsoft Teams'}
  />
);

export default MSTeams;
